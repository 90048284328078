import React, { useState, useEffect, useMemo } from 'react';
import { DataGrid, getGridStringOperators } from '@mui/x-data-grid';
import { Box, Typography } from '@mui/material';
import { dataGridStyles } from '../styles';
import Loader from '../components/Loader';
import axios from 'axios';
import dayjs from 'dayjs';
import { getUserInfoFromToken } from '../services/authService';

const DomainsAnalitics = () => {
  const token = localStorage.getItem('authToken');
  const [projectsData, setProjectsData] = useState({});
  const [allDomains, setAllDomains] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { role, username, team } = getUserInfoFromToken();

  const stringOnlyContainsOperators = getGridStringOperators().filter((operator) => operator.value === 'contains');

  useEffect(() => {
    const fetchDomainsData = async () => {
      try {
        const params = { role, username };
        if (team) {
          params.team = JSON.stringify(team);
        }

        const response = await axios.get('https://backend.moorpan.com/get-domains', { params, headers: {
          Authorization: `Bearer ${token}`
        } });
        setProjectsData(response.data);

        const allDomainsList = Object.keys(response.data).reduce((acc, project) => {
          const domainsWithProject = response.data[project]
            .filter((domain) => !domain.status?.startsWith('Trash Hold'))
            .map((domain) => ({
              ...domain,
              project,
            }));
          return [...acc, ...domainsWithProject];
        }, []);
        setAllDomains(allDomainsList);
      } catch (err) {
        console.log(err);
        setError('Ошибка при загрузке данных');
      } finally {
        setLoading(false);
      }
    };

    fetchDomainsData();
  }, [role, username, team]);

  const calculateLifeDuration = (dateChecked, dateDead) => {
    if (!dateChecked) return null;
    if (dateDead !== null) {
      const now = dayjs(dateDead);
      const checkedDate = dayjs(dateChecked);
      return now.diff(checkedDate, 'day');
    }
    const now = dayjs();
    const checkedDate = dayjs(dateChecked);
    return now.diff(checkedDate, 'day');
  };
  const getRowClassName = (lifeDuration) => {
    if (lifeDuration === null) return '';
    if (lifeDuration <= 7) return 'row-red';
    if (lifeDuration <= 14) return 'row-yellow';
    if (lifeDuration <= 31) return 'row-orange';
    return 'row-green';
  };

  const renderProjectTables = useMemo(() => {
    return Object.keys(projectsData).map((project) => (
      <Box key={project} sx={{ marginBottom: 4 }}>
        <Typography variant="h5" align="center" gutterBottom>
          {project} Domains Analytics
        </Typography>
        <DataGrid
          rows={projectsData[project]}
          columns={[
            { field: 'domain', headerName: 'Domain', flex: 1, sortable: false, filterOperators: stringOnlyContainsOperators },
            { field: 'status', headerName: 'Status', flex: 1, sortable: false, filterOperators: stringOnlyContainsOperators },
            { field: 'status_ads', headerName: 'Status Ads', flex: 1, sortable: false, filterOperators: stringOnlyContainsOperators },
            {
              field: 'date_checked',
              headerName: 'Date start index',
              flex: 1,
              type: 'dateTime',
              valueGetter: (params) => {
                const dateChecked = params;
                return dateChecked ? new Date(dateChecked) : '';
              },
              sortable: true,
              filterOperators: stringOnlyContainsOperators,
            },
            {
              field: 'lifeDuration',
              headerName: 'Life Duration (days)',
              flex: 1,
              renderCell: (cellValues) => {
                if (!cellValues || !cellValues.row) {
                  return "No Data";
                }
                const dateChecked = cellValues.row.date_checked;
                const dateDead = cellValues.row.date_dead || null;
                return dateChecked ? calculateLifeDuration(dateChecked, dateDead) : "No Data";
              },
              sortable: true,
              filterOperators: stringOnlyContainsOperators,
            },
          ]}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
          pageSizeOptions={[25, 50, 100, 250, { value: -1, label: 'All' }]}
          getRowId={(row) => row.domain}
          getRowClassName={(params) => {
            if (!params || !params.row) {
              return '';
            }
            const dateChecked = params.row?.date_checked;
            const dateDead = params.row?.date_dead || null;
            if (!dateChecked) {
              return '';
            }
           return getRowClassName(calculateLifeDuration(dateChecked, dateDead))
          }}
          sx={dataGridStyles}
        />
      </Box>
    ));
  }, [projectsData, stringOnlyContainsOperators]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Typography variant="h6" color="error" align="center">{error}</Typography>;
  }

  return (
    <Box>
      <Typography variant="h4" align="center" gutterBottom>
        Domains Analytics
      </Typography>

      {(role === 'admin' || role === 'tlead') && (
        <Box sx={{ marginBottom: 4 }}>
          <Typography variant="h5" align="center" gutterBottom>
            All Domains Analytics
          </Typography>
          <DataGrid
            rows={allDomains}
            columns={[
              { field: 'domain', headerName: 'Domain', flex: 1, sortable: false, filterOperators: stringOnlyContainsOperators },
              { field: 'project', headerName: 'Buyer', flex: 1, sortable: false, filterOperators: stringOnlyContainsOperators },
              { field: 'status', headerName: 'Status', flex: 1, sortable: false, filterOperators: stringOnlyContainsOperators },
              { field: 'status_ads', headerName: 'Status Ads', flex: 1, sortable: false, filterOperators: stringOnlyContainsOperators },
              {
                field: 'date_checked',
                headerName: 'Date start index',
                flex: 1,
                type: 'dateTime',
                valueGetter: (params) => {
                  const dateChecked = params;
                  return dateChecked ? new Date(dateChecked) : '';
                },
                sortable: true,
                filterOperators: stringOnlyContainsOperators,
              },
              {
                field: 'lifeDuration',
                headerName: 'Life Duration (days)',
                flex: 1,
                renderCell: (cellValues) => {
                  if (!cellValues || !cellValues.row) {
                    return "No Data";
                  }
                  const dateChecked = cellValues.row.date_checked;
                  const dateDead = cellValues.row.date_dead || null;
                  return dateChecked ? calculateLifeDuration(dateChecked, dateDead) : "No Data";
                },
                sortable: true,
                filterOperators: stringOnlyContainsOperators,
              },
            ]}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 25, page: 0 },
              },
            }}
            pageSizeOptions={[25, 50, 100, 250, { value: -1, label: 'All' }]}
            getRowId={(row) => row.domain}
            getRowClassName={(params) => {
              if (!params || !params.row) {
                return '';
              }
              const dateChecked = params.row?.date_checked;
              const dateDead = params.row?.date_dead || null;
              if (!dateChecked) {
                return '';
              }
              return getRowClassName(calculateLifeDuration(dateChecked, dateDead));
            }}
            sx={dataGridStyles}
          />
        </Box>
      )}

      {role === 'user' && renderProjectTables}
    </Box>
  );
};

export default DomainsAnalitics;
