import React, {
  useState,
  useEffect,
  useMemo,
  useCallback
} from "react";
import {
  Box,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
  TextField,
  Autocomplete
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import axios from "axios";
import RefreshIcon from "@mui/icons-material/Refresh";

import { getUserInfoFromToken } from "../services/authService";
import { dataGridStyles } from "../styles";
import Loader from '../components/Loader';

dayjs.extend(customParseFormat);

const RED_STATUSES = [
  "обход системы",
  "бизнес модель",
  "бизнес практика",
  "компрометирующий сайт"
];

export default function FinancialAnalyticsGoogle() {
  const token = localStorage.getItem("authToken");
  const userInfo = useMemo(() => getUserInfoFromToken(), []);
  const { role, username, team } = userInfo;

  const isFinance = role === "finance";
  const isUser = role === "user";
  const isTlead = role === "tlead";

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [finAccounts, setFinAccounts] = useState([]);
  const [checkedMap, setCheckedMap] = useState({});

  const [sellerRows, setSellerRows] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedSeller, setSelectedSeller] = useState(null);
  const [dialogRows, setDialogRows] = useState([]);
  const [dialogBuyerFilterMap, setDialogBuyerFilterMap] = useState({});

  const [dynStartDate, setDynStartDate] = useState(dayjs("2025-01-01"));
  const [dynEndDate, setDynEndDate] = useState(dayjs("2025-12-31"));

  const [buyerFilterMap, setBuyerFilterMap] = useState({});

  const [showOnlyRed, setShowOnlyRed] = useState(false);
  const [showOnlyBlack, setShowOnlyBlack] = useState(false);

  const [dialogSortModel, setDialogSortModel] = useState([]);

  const [selectedColumns, setSelectedColumns] = useState([]);

  const [table3VisibilityModel, setTable3VisibilityModel] = useState({});
  const [table3Widths, setTable3Widths] = useState({});

  const [domainFilter, setDomainFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [idAccountFilter, setIdAccountFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState([]);
  const [buyerFilterT3, setBuyerFilterT3] = useState([]);

  const [sellerStartDate, setSellerStartDate] = useState(null);
  const [sellerEndDate, setSellerEndDate] = useState(null);

  const fetchAccountFinGoogle = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://backend.moorpan.com/get-account-fin-google",
        {
          headers: {
            role,
            username,
            Authorization: `Bearer ${token}`
          }
        }
      );
      let data = response.data || [];

      if (isUser) {
        data = data.filter((acc) => acc.buyer === username);
      } else if (isTlead && Array.isArray(team)) {
        data = data.filter((acc) => team.includes(acc.buyer));
      }

      data = data.map((acc, idx) => ({ ...acc, originalIndex: idx }));

      setFinAccounts(data);
      setError(null);
    } catch (err) {
      console.error("Error loading google-fin accounts:", err);
      setError("Ошибка при загрузке google-fin");
    } finally {
      setLoading(false);
    }
  }, [role, username, token, team, isUser, isTlead]);

  const fetchCheckedGoogle = useCallback(async () => {
    if (!isFinance) return;
    try {
      const resp = await axios.get("https://backend.moorpan.com/get-checked-google", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setCheckedMap(resp.data.checked || {});
    } catch (err) {
      console.error("Error get-checked-google:", err);
    }
  }, [isFinance, token]);

  useEffect(() => {
    fetchAccountFinGoogle();
    fetchCheckedGoogle();
  }, [fetchAccountFinGoogle, fetchCheckedGoogle]);

  useEffect(() => {
    const uniqueBuyers = [...new Set(finAccounts.map((acc) => acc.buyer))];
    const newBuyerMap = {};
    uniqueBuyers.forEach((b) => {
      newBuyerMap[b] = true;
    });
    setBuyerFilterMap(newBuyerMap);
  }, [finAccounts]);

  const handleToggleBuyer = useCallback((buyer) => {
    setBuyerFilterMap((prev) => ({
      ...prev,
      [buyer]: !prev[buyer]
    }));
  }, []);

  const handleSelectAllBuyers = useCallback(() => {
    setBuyerFilterMap((prev) => {
      const newMap = {};
      Object.keys(prev).forEach((b) => {
        newMap[b] = true;
      });
      return newMap;
    });
  }, []);

  const handleUnselectAllBuyers = useCallback(() => {
    setBuyerFilterMap((prev) => {
      const newMap = {};
      Object.keys(prev).forEach((b) => {
        newMap[b] = false;
      });
      return newMap;
    });
  }, []);

  const filteredAccounts = useMemo(() => {
    return finAccounts.filter((acc) => {
      if (!buyerFilterMap[acc.buyer]) return false;
      if (isFinance && checkedMap[acc.idAccount]) return false;
      return true;
    });
  }, [finAccounts, buyerFilterMap, isFinance, checkedMap]);

  function filterByDateRange(accounts, startDate, endDate) {
    if (!startDate && !endDate) return accounts;
  
    return accounts.filter((acc) => {
      const issueDate = dayjs(acc.dateOfIssue, "DD.MM.YYYY");
      console.log(issueDate)
  
      if (!issueDate.isValid()) {
        return false;
      }
  
      if (startDate && issueDate.isBefore(startDate.startOf("day"))) {
        return false;
      }
  
      if (endDate && issueDate.isAfter(endDate.endOf("day"))) {
        return false;
      }
  
      return true;
    });
  }

  const sellerRowsData = useMemo(() => {
    const accountsInRange = filterByDateRange(filteredAccounts, sellerStartDate, sellerEndDate);
    const map = {};
    accountsInRange.forEach((acc) => {
      const s = acc.seller || "Unknown";
      if (!map[s]) {
        map[s] = { seller: s, totalBalance: 0, totalCost: 0 };
      }
      const bal = parseFloat(acc.accBalance) || 0;
      const cost = parseFloat(acc.totalCost) || 0;
      map[s].totalBalance += bal;
      map[s].totalCost += cost;
    });
  
    return Object.keys(map).map((seller) => {
      const totalBal = map[seller].totalBalance;
      const totalCost = map[seller].totalCost;
      const current = totalBal - totalCost;
      return {
        id: seller,
        seller,
        totalBalance: totalBal.toFixed(2),
        totalCost: totalCost.toFixed(2),
        currentTotalBalance: current.toFixed(2),
      };
    });
  },  [  filteredAccounts, 
    sellerStartDate,
    sellerEndDate]);

  useEffect(() => {
    setSellerRows(sellerRowsData);
  }, [sellerRowsData]);

  const handleOpenDetailsDialog = useCallback((seller) => {
    setSelectedSeller(seller);

    const rows = finAccounts.filter(
      (acc) => (acc.seller || "").trim().toLowerCase() === seller.trim().toLowerCase()
    );
    setDialogRows(rows);

    const uniqueBuyers = [...new Set(rows.map((acc) => acc.buyer))];
    const buyerMap = {};
    uniqueBuyers.forEach((b) => {
      buyerMap[b] = true;
    });
    setDialogBuyerFilterMap(buyerMap);

    setOpenDialog(true);
  }, [finAccounts]);

  const sellerColumns = useMemo(() => [
    { field: "seller", headerName: "Seller", minWidth: 120, flex: 1 },
    { field: "totalBalance", headerName: "Total Balance", minWidth: 120, flex: 1 },
    { field: "totalCost", headerName: "Total Cost", minWidth: 120, flex: 1 },
    {
      field: "currentTotalBalance",
      headerName: "Current Total Balance",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "details",
      headerName: "Details",
      minWidth: 100,
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          onClick={() => handleOpenDetailsDialog(params.row.seller)}
        >
          Details
        </Button>
      ),
    },
  ], [handleOpenDetailsDialog]);

  const handleCloseDialog = useCallback(() => {
    setOpenDialog(false);
    setSelectedSeller(null);
    setDialogRows([]);
    setDialogBuyerFilterMap({});
    setShowOnlyRed(false);
    setShowOnlyBlack(false);
    setDialogSortModel([]);
    setSelectedColumns([]);
  }, []);

  const handleToggleDialogBuyer = useCallback((buyer) => {
    setDialogBuyerFilterMap((prev) => ({
      ...prev,
      [buyer]: !prev[buyer]
    }));
  }, []);

  const handleDialogSelectAllBuyers = useCallback(() => {
    setDialogBuyerFilterMap((prev) => {
      const newMap = {};
      Object.keys(prev).forEach((b) => {
        newMap[b] = true;
      });
      return newMap;
    });
  }, []);

  const handleDialogUnselectAllBuyers = useCallback(() => {
    setDialogBuyerFilterMap((prev) => {
      const newMap = {};
      Object.keys(prev).forEach((b) => {
        newMap[b] = false;
      });
      return newMap;
    });
  }, []);

  const toggleShowOnlyRed = useCallback(() => {
    setShowOnlyBlack(false);
    setShowOnlyRed((prev) => !prev);
  }, []);
  const toggleShowOnlyBlack = useCallback(() => {
    setShowOnlyRed(false);
    setShowOnlyBlack((prev) => !prev);
  }, []);

  const dynamicDateColumns = useMemo(() => {
    const cols = [];
    if (!dynStartDate || !dynEndDate) return cols;

    let cur = dynStartDate.clone().startOf("day");
    const end = dynEndDate.clone().startOf("day");

    while (cur.isSameOrBefore(end, "day")) {
      const dateStr = cur.format("DD.MM.YYYY");
      cols.push({
        field: dateStr,
        headerName: dateStr,
        minWidth: 90,
        sortable: true,
        renderCell: (params) => {
          const val = params.row[dateStr];
          return val || "";
        },
      });
      cur = cur.add(1, "day");
    }
    return cols;
  }, [dynStartDate, dynEndDate]);

  const handleSetChecked = useCallback(async (id_account) => {
    if (!isFinance) return;
    const currentVal = !!checkedMap[id_account];
    const newVal = !currentVal;
    try {
      await axios.post(
        "https://backend.moorpan.com/set-checked-google",
        { id_account, isChecked: newVal },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setCheckedMap((prev) => ({ ...prev, [id_account]: newVal }));
    } catch (err) {
      console.error("Error setCheckedGoogle:", err);
    }
  }, [isFinance, checkedMap, token]);

  const renderHeaderWithCheckbox = useCallback((params) => {
    const { colDef } = params;
    const field = colDef.field;
    const isSelected = selectedColumns.includes(field);
  
    const handleChange = (e) => {
      e.stopPropagation();
      const checked = e.target.checked;
      setSelectedColumns((prev) => {
        if (checked) {
          return [...prev, field];
        } else {
          return prev.filter((f) => f !== field);
        }
      });
    };
  
    return (
      <Box
        onClick={(e) => e.stopPropagation()}
        display="flex"
        alignItems="center"
      >
        <Checkbox
          size="small"
          checked={isSelected}
          onClick={(e) => e.stopPropagation()}
          onChange={handleChange}
        />
        {colDef.headerName}
      </Box>
    );
  }, [selectedColumns]);

  const dialogBaseCols = useMemo(() => {
    const base = [
      {
        field: "buyer",
        headerName: "Buyer",
        minWidth: 100,
        flex: 1,
        sortable: true,
        renderCell: (params) => <span>{params.value}{" "}</span>
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: 150,
        flex: 1,
        sortable: true,
        renderCell: (params) => <span>{params.value}{" "}</span>
      },
      {
        field: "idAccount",
        headerName: "ID Account",
        minWidth: 120,
        flex: 1,
        sortable: true
      },
      {
        field: "statusAds",
        headerName: "Status ADS",
        minWidth: 100,
        flex: 1,
        sortable: true
      },
      {
        field: "accBalance",
        headerName: "Account Balance",
        minWidth: 120,
        flex: 1,
        sortable: true
      },
      {
        field: "totalCost",
        headerName: "Spend",
        minWidth: 80,
        flex: 1,
        sortable: true
      },
      {
        field: "differenceCost",
        headerName: "Difference Cost",
        minWidth: 120,
        flex: 1,
        sortable: true
      },
      {
        field: "currentBalance",
        headerName: "Current balance",
        minWidth: 120,
        flex: 1,
        sortable: true,
        renderCell: (params) => {
          const bal = parseFloat(params.row.accBalance) || 0;
          const cost = parseFloat(params.row.totalCost) || 0;
          return (bal - cost).toFixed(2);
        }
      }
    ];

    if (isFinance) {
      base.unshift({
        field: "checked",
        headerName: "",
        width: 50,
        sortable: false,
        renderCell: (params) => {
          const idAcc = params.row.idAccount;
          const val = !!checkedMap[idAcc];
          return (
            <Checkbox
              checked={val}
              onChange={() => handleSetChecked(idAcc)}
            />
          );
        }
      });
    }

    if (isFinance) {
      return base.map((col) => ({
        ...col,
        renderHeader: renderHeaderWithCheckbox
      }));
    }
    return base;
  }, [isFinance, checkedMap, handleSetChecked, renderHeaderWithCheckbox]);

  const dialogColumns = useMemo(() => {
    const dynCols = dynamicDateColumns.map((col) =>
      isFinance
        ? { ...col, renderHeader: renderHeaderWithCheckbox }
        : col
    );
    return [...dialogBaseCols, ...dynCols];
  }, [dialogBaseCols, dynamicDateColumns, isFinance, renderHeaderWithCheckbox]);

  const filteredDialogRows = useMemo(() => {
    return dialogRows.filter((acc) => {

      if (!dialogBuyerFilterMap[acc.buyer]) return false;
  
      const isRed = RED_STATUSES.includes(acc.statusAds);
      const isChecked = !!checkedMap[acc.idAccount];
  
      if (showOnlyRed) {

        if (!isRed || isChecked) return false;
      }

      if (showOnlyBlack) {
        if (!isChecked) return false;
      }
  
      return true;
    });
  }, [
    dialogRows,
    dialogBuyerFilterMap,
    showOnlyRed,
    showOnlyBlack,
    checkedMap
  ]);

  const sortDialogRows = useCallback((rows, sortModel) => {
    if (!sortModel || sortModel.length === 0) {
      return [...rows].sort((a, b) => {
        const aCh = checkedMap[a.idAccount] ? 1 : 0;
        const bCh = checkedMap[b.idAccount] ? 1 : 0;
        if (aCh !== bCh) {
          return aCh - bCh;
        }
        return a.originalIndex - b.originalIndex;
      });
    }
    const { field, sort } = sortModel[0];
    return [...rows].sort((a, b) => {
      const aCh = checkedMap[a.idAccount] ? 1 : 0;
      const bCh = checkedMap[b.idAccount] ? 1 : 0;

      if (aCh !== bCh) {
        return aCh - bCh;
      }

      if (aCh === 1 && bCh === 1) {
        return a.originalIndex - b.originalIndex;
      }

      const valA = a[field];
      const valB = b[field];

      const numA = parseFloat(valA);
      const numB = parseFloat(valB);
      if (!isNaN(numA) && !isNaN(numB)) {
        return sort === "asc" ? numA - numB : numB - numA;
      }
      const strA = String(valA || "");
      const strB = String(valB || "");
      if (strA < strB) return sort === "asc" ? -1 : 1;
      if (strA > strB) return sort === "asc" ? 1 : -1;
      return 0;
    });
  }, [checkedMap]);

  const finalDialogRows = useMemo(() => {
    const sorted = sortDialogRows(filteredDialogRows, dialogSortModel);
    return sorted.map((row, idx) => ({
      ...row,
      id: row.idAccount || `dialog-row-${idx}`
    }));
  }, [filteredDialogRows, dialogSortModel, sortDialogRows]);

  const handleDialogSortModelChange = useCallback((model) => {
    setDialogSortModel(model);
  }, []);

  const fetchTable3ColumnSettings = useCallback(async () => {
    try {
      const resp = await axios.get(
        "https://backend.moorpan.com/get-column-settings",
        {
          params: { username, tableName: "FinancialAnalyticsGoogle_Table3" },
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      if (resp.data && Array.isArray(resp.data)) {
        const loadedVisibility = {};
        const loadedWidths = {};
        resp.data.forEach((col) => {
          loadedVisibility[col.field] = !col.hide;
          if (col.width) loadedWidths[col.field] = col.width;
        });
        setTable3VisibilityModel(loadedVisibility);
        setTable3Widths(loadedWidths);
      }
    } catch (err) {
      console.error("Ошибка при загрузке настроек Table #3:", err);
    }
  }, [username, token]);

  useEffect(() => {
    fetchTable3ColumnSettings();
  }, [fetchTable3ColumnSettings]);

  const handleTable3VisibilityChange = useCallback(async (newVisibilityModel) => {
    setTable3VisibilityModel(newVisibilityModel);

    const columnConfig = Object.keys(newVisibilityModel).map((field) => ({
      field,
      hide: !newVisibilityModel[field],
      width: table3Widths[field] || null
    }));
    try {
      await axios.post(
        "https://backend.moorpan.com/save-column-settings",
        {
          username,
          tableName: "FinancialAnalyticsGoogle_Table3",
          columnConfig
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log("Table #3 visibility saved successfully.");
    } catch (err) {
      console.error("Error saving Table #3 visibility:", err);
    }
  }, [username, token, table3Widths]);

  const handleTable3ColumnResize = useCallback(async (params) => {
    const { colDef, width } = params;
    const updated = { ...table3Widths, [colDef.field]: width };
    setTable3Widths(updated);

    const columnConfig = Object.keys(updated).map((f) => ({
      field: f,
      hide: !table3VisibilityModel[f],
      width: updated[f]
    }));
    try {
      await axios.post(
        "https://backend.moorpan.com/save-column-settings",
        {
          username,
          tableName: "FinancialAnalyticsGoogle_Table3",
          columnConfig
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log("Table #3 column widths saved.");
    } catch (err) {
      console.error("Error saving Table #3 widths:", err);
    }
  }, [table3Widths, table3VisibilityModel, username, token]);

  const table3Columns = useMemo(() => {
    const cols = [
      {
        field: "dateOfIssue",
        headerName: "Date of Issue",
        minWidth: 120,
        valueFormatter: (params) => {
          if (!params) return "";
          return dayjs(params).format("DD/MM/YYYY");
        }
      },
      { field: "buyer", headerName: "Buyer", minWidth: 80 },
      { field: "email", headerName: "Email", minWidth: 140 },
      { field: "seller", headerName: "Seller", minWidth: 80 },
      { field: "domain", headerName: "Domain", minWidth: 120 },
      { field: "accountType", headerName: "Account Type", minWidth: 100 },
      { field: "idAccount", headerName: "ID Account", minWidth: 120 },
      { field: "statusAds", headerName: "Status ADS", minWidth: 100 },
      { field: "accBalance", headerName: "Account Bal", minWidth: 90 },
      { field: "totalCost", headerName: "Total Spend", minWidth: 90 }
    ].map((col) => ({
      ...col,
      width: table3Widths[col.field] || col.minWidth || 100
    }));

    return cols;
  }, [table3Widths]);

  const filteredTable3Rows = useMemo(() => {

    const baseRows = finAccounts.map((acc, i) => ({
      ...acc,
      id: acc.idAccount || `table3-row-${i}`
    }));

    if (!isFinance) return baseRows;

    return baseRows.filter((row) => {

      if (domainFilter) {
        if (!row.domain?.toLowerCase().includes(domainFilter.toLowerCase())) {
          return false;
        }
      }

      if (emailFilter) {
        if (!row.email?.toLowerCase().includes(emailFilter.toLowerCase())) {
          return false;
        }
      }

      if (idAccountFilter) {
        if (!String(row.idAccount || "").toLowerCase().includes(idAccountFilter.toLowerCase())) {
          return false;
        }
      }

      if (statusFilter.length > 0) {
        if (!statusFilter.includes(row.statusAds)) {
          return false;
        }
      }

      if (buyerFilterT3.length > 0) {
        if (!buyerFilterT3.includes(row.buyer)) {
          return false;
        }
      }
      return true;
    });
  }, [
    finAccounts,
    isFinance,
    domainFilter,
    emailFilter,
    idAccountFilter,
    statusFilter,
    buyerFilterT3
  ]);

  const allStatuses = useMemo(() => {
    const stSet = new Set(finAccounts.map((acc) => acc.statusAds).filter(Boolean));
    return Array.from(stSet);
  }, [finAccounts]);

  const allBuyersT3 = useMemo(() => {
    const bSet = new Set(finAccounts.map((acc) => acc.buyer).filter(Boolean));
    return Array.from(bSet);
  }, [finAccounts]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ p: 2 }}>
        <Typography variant="h4" align="center">
          Financial Analytics
        </Typography>

        {loading ? (
          <Loader />
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <>
          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <DatePicker
            label="Start date"
            value={sellerStartDate}
            onChange={(newValue) => {
              if (newValue?.isValid()) {
                setSellerStartDate(newValue);
              } else {
                setSellerStartDate(null);
              }
            }}
          />
          <DatePicker
            label="End date"
            value={sellerEndDate}
            onChange={(newValue) => {
              if (newValue?.isValid()) {
                setSellerEndDate(newValue);
              } else {
                setSellerEndDate(null);
              }
            }}
          />
            <IconButton
              onClick={() => {
                fetchAccountFinGoogle();
                fetchCheckedGoogle();
              }}
            >
              <RefreshIcon />
            </IconButton>
          </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 2,
                my: 2
              }}
            >
              {Object.keys(buyerFilterMap).map((buyer) => (
                <FormControlLabel
                  key={buyer}
                  control={
                    <Checkbox
                      checked={buyerFilterMap[buyer]}
                      onChange={() => handleToggleBuyer(buyer)}
                    />
                  }
                  label={buyer}
                />
              ))}
              <Button variant="outlined" onClick={handleSelectAllBuyers}>
                Select All
              </Button>
              <Button variant="outlined" color="warning" onClick={handleUnselectAllBuyers}>
                Unselect All
              </Button>
            </Box>

            <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
              Seller Summary
            </Typography>
            <DataGrid
              rows={sellerRows}
              columns={sellerColumns}
              sx={dataGridStyles}
              pageSizeOptions={[25, 50, 100]}
              getRowId={(row) => row.id}
            />

            <Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              maxWidth="xl"
              fullWidth
            >
              <DialogTitle>Details for {selectedSeller}</DialogTitle>
              <DialogContent>
                <Box sx={{ display: "flex", gap: 2, mb: 2, mt: 2 }}>
                  <DatePicker
                    label="Day Start"
                    value={dynStartDate}
                    onChange={(val) => {
                      if (val && val.isValid()) {
                        setDynStartDate(val.startOf("day"));
                      }
                    }}
                  />
                  <DatePicker
                    label="Day End"
                    value={dynEndDate}
                    onChange={(val) => {
                      if (val && val.isValid()) {
                        setDynEndDate(val.startOf("day"));
                      }
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: 2,
                    mb: 2
                  }}
                >
                  {Object.keys(dialogBuyerFilterMap).map((b) => (
                    <FormControlLabel
                      key={b}
                      control={
                        <Checkbox
                          checked={dialogBuyerFilterMap[b]}
                          onChange={() => handleToggleDialogBuyer(b)}
                        />
                      }
                      label={b}
                    />
                  ))}
                  <Button variant="outlined" onClick={handleDialogSelectAllBuyers}>
                    Select All
                  </Button>
                  <Button variant="outlined" color="warning" onClick={handleDialogUnselectAllBuyers}>
                    Unselect All
                  </Button>
                </Box>

                {isFinance && (
                  <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                    <Button variant="outlined" onClick={toggleShowOnlyRed}>
                      {showOnlyRed ? "Show all" : "Show only red rows"}
                    </Button>
                    <Button variant="outlined" onClick={toggleShowOnlyBlack}>
                      {showOnlyBlack ? "Show all" : "Show only black rows"}
                    </Button>
                  </Box>
                )}

                <DataGrid
                  rows={finalDialogRows}
                  columns={dialogColumns}
                  sx={(theme) => {
                    const baseStyles = {
                      ...dataGridStyles,
                      "& .MuiDataGrid-row": {
                        display: "block",   
                      },
                      "& .MuiDataGrid-cell": {
                        display: "inline-block",
                        whiteSpace: "pre",
                        userSelect: "none",
                        color: "#1e1111",
                        fontWeight: "700",
                        backgroundColor: "#fdfeff",
                        textOverflow: "unset",
                      },
                      "& .row-red": {
                        backgroundColor: "rgba(255, 0, 0, 0.1) !important",
                        color: "#d90000 !important"
                      },
                      "& .checked-row-custom": {
                        backgroundColor: "#000000 !important",
                        color: "#ffffff !important"
                      },
                      "& .MuiDataGrid-columnHeaderTitleContainerContent .MuiCheckbox-root": {
                        color: "white"
                      },
                      "& .MuiDataGrid-columnHeaderTitleContainerContent .MuiBox-root": {
                        color: "white",
                        fontWeight: 500
                      }
                    };

                    selectedColumns.forEach((field) => {
                      baseStyles[`& .MuiDataGrid-cell[data-field="${field}"]`] = {
                        userSelect: "text"
                      };
                    });

                    return baseStyles;
                  }}
                  pageSizeOptions={[25, 50, 100]}
                  initialState={{
                    pagination: { paginationModel: { pageSize: 25, page: 0 } }
                  }}
                  sortingMode="server"
                  sortModel={dialogSortModel}
                  onSortModelChange={handleDialogSortModelChange}
                  getRowClassName={(params) => {
                    const st = params.row.statusAds;
                    const ch = !!checkedMap[params.row.idAccount];

                    if (RED_STATUSES.includes(st) && !ch) {
                      return "row-red";
                    }
                    
                    if (ch) return "checked-row-custom";
                    return "";
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog}>Close</Button>
              </DialogActions>
            </Dialog>

            <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
              Account Info
            </Typography>

              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, mb: 2 }}>
                <TextField
                  label="Domain filter"
                  value={domainFilter}
                  onChange={(e) => setDomainFilter(e.target.value)}
                />
                <TextField
                  label="Email filter"
                  value={emailFilter}
                  onChange={(e) => setEmailFilter(e.target.value)}
                />
                <TextField
                  label="ID Account filter"
                  value={idAccountFilter}
                  onChange={(e) => setIdAccountFilter(e.target.value)}
                />
                <Autocomplete
                  multiple
                  options={allStatuses}
                  value={statusFilter}
                  onChange={(event, newVal) => setStatusFilter(newVal)}
                  renderInput={(params) => <TextField {...params} label="Status filter" />}
                  sx={{ minWidth: 200 }}
                />
                <Autocomplete
                  multiple
                  options={allBuyersT3}
                  value={buyerFilterT3}
                  onChange={(event, newVal) => setBuyerFilterT3(newVal)}
                  renderInput={(params) => <TextField {...params} label="Buyer filter" />}
                  sx={{ minWidth: 200 }}
                />
              </Box>

            <DataGrid
              rows={filteredTable3Rows}
              columns={table3Columns}
              sx={dataGridStyles}
              pageSizeOptions={[25, 50, 100]}
              columnVisibilityModel={table3VisibilityModel}
              onColumnVisibilityModelChange={handleTable3VisibilityChange}
              onColumnWidthChange={handleTable3ColumnResize}
            />
          </>
        )}
      </Box>
    </LocalizationProvider>
  );
}
