import React, { useMemo, useState, useEffect, useCallback } from "react";
import { DataGrid, getGridStringOperators } from "@mui/x-data-grid";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Button,
  Typography,
  useMediaQuery,
  Box,
  Divider,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import axios from "axios";
import dayjs from "dayjs";
import Loader from "../components/Loader";
import { processMediaBuyerData } from "../utils";
import { dataGridStyles } from "../styles";
import RefreshIcon from "@mui/icons-material/Refresh";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import { getUserInfoFromToken } from "../services/authService";

ChartJS.register(ArcElement, Tooltip, Legend);

const MediaBuyerAnalytics = () => {
  const token = localStorage.getItem('authToken');
  const { role, username, team: userTeam } = useMemo(() => getUserInfoFromToken(), []);
  const team = useMemo(() => (Array.isArray(userTeam) ? userTeam.slice() : []), [userTeam]);

  const [leads, setLeads] = useState([]);
  const [conversions, setConversions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState(dayjs().startOf("day"));
  const [toDate, setToDate] = useState(dayjs().endOf("day"));
  const [selectedBuyers, setSelectedBuyers] = useState([]);
  const [allBuyers, setAllBuyers] = useState([]);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [geoDialogOpen, setGeoDialogOpen] = useState(false);
  const [selectedGeo, setSelectedGeo] = useState(null);
  const [dialogRows, setDialogRows] = useState([]);

  const stringOnlyContainsOperators = getGridStringOperators().filter(
    (operator) => operator.value === "contains"
  );

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const params = {
        fromDate: fromDate.format("YYYY-MM-DD HH:mm:ss"),
        toDate: toDate.format("YYYY-MM-DD HH:mm:ss"),
        itemsPerPage: 1000,
      };

      if (role === "user" && username) {
        params.custom4 = username;
      }

      const response = await axios.get(
        "https://backend.moorpan.com/get-leads-and-conversions",
        { params, headers: {
          Authorization: `Bearer ${token}`
        }
        }
      );

      let leadsData = response.data.leads;
      let conversionsData = response.data.conversions;

      if (role === "tlead" && Array.isArray(team)) {
        leadsData = leadsData.filter((entry) => team.includes(entry.custom4));
        conversionsData = conversionsData.filter((entry) =>
          team.includes(entry.custom4)
        );
      }

      setLeads(leadsData);
      setConversions(conversionsData);

      const uniqueBuyers = [
        ...new Set(leadsData.map((entry) => entry.custom4 || "empty")),
      ];
      setAllBuyers(uniqueBuyers);
      setSelectedBuyers(uniqueBuyers);
    } catch (error) {
      console.error("Ошибка при загрузке данных:", error);
    } finally {
      setLoading(false);
    }
  }, [fromDate, toDate, role, username, team]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const rows = useMemo(() => {
    const processedData = processMediaBuyerData(leads, conversions, selectedBuyers);

    const totalLeads = processedData.reduce((acc, row) => acc + row.leads, 0);
    const totalDeposits = processedData.reduce((acc, row) => acc + row.deposit, 0);
    const totalCr = totalLeads > 0 ? ((totalDeposits / totalLeads) * 100).toFixed(2) : "0";

    const totalRow = {
      id: "total",
      name: "Total",
      leads: totalLeads,
      deposit: totalDeposits,
      cr: totalCr,
    };

    return [...processedData, totalRow];
  }, [leads, conversions, selectedBuyers]);

  const processGeoData = (leads, conversions, selectedBuyers) => {
    const leadsMap = {};
    const geoMap = {};

    leads.forEach((lead) => {
      const mediaBuyerName = lead.custom4 || "empty";
      if (selectedBuyers.includes(mediaBuyerName)) {
        const traderID = lead.traderID.toString();
        leadsMap[traderID] = lead;
      }
    });

    Object.values(leadsMap).forEach((lead) => {
      const countryCode = lead.countryCode || "unknown";
      if (!geoMap[countryCode]) {
        geoMap[countryCode] = {
          countryCode,
          leads: 0,
          deposit: 0,
          cr: "0.00",
        };
      }
      geoMap[countryCode].leads += 1;
    });

    conversions.forEach((conversion) => {
      const traderID = conversion.traderID.toString();
      const countryCode = conversion.countryCode || "unknown";
      const mediaBuyerName = conversion.custom4 || "empty";

      if (selectedBuyers.includes(mediaBuyerName)) {
        if (leadsMap[traderID]) {
          geoMap[countryCode].deposit += 1;
        } else {
          if (!geoMap[countryCode]) {
            geoMap[countryCode] = {
              countryCode,
              leads: 0,
              deposit: 0,
              cr: "0.00",
            };
          }
          geoMap[countryCode].deposit += 1;
        }
      }
    });

    Object.keys(geoMap).forEach((country) => {
      const leadsCount = geoMap[country].leads;
      const deposits = geoMap[country].deposit;
      geoMap[country].cr = leadsCount > 0 ? ((deposits / leadsCount) * 100).toFixed(2) : "0.00";
    });

    return geoMap;
  };

  const geoDataProcessed = useMemo(() => {
    return processGeoData(leads, conversions, selectedBuyers);
  }, [leads, conversions, selectedBuyers]);

  const geoDataRows = useMemo(() => {
    const rows = Object.keys(geoDataProcessed).map((key, index) => ({
      id: `geo-${index}`,
      countryCode: key,
      leads: geoDataProcessed[key].leads,
      deposit: geoDataProcessed[key].deposit,
      cr: geoDataProcessed[key].cr,
    }));

    const totalLeads = rows.reduce((acc, row) => acc + row.leads, 0);
    const totalDeposits = rows.reduce((acc, row) => acc + row.deposit, 0);
    const totalCr = totalLeads > 0 ? ((totalDeposits / totalLeads) * 100).toFixed(2) : "0";

    const totalRow = {
      id: "geo-total",
      countryCode: "Total",
      leads: totalLeads,
      deposit: totalDeposits,
      cr: totalCr,
    };

    return [...rows, totalRow];
  }, [geoDataProcessed]);

  const chartData = useMemo(() => {
    return {
      labels: Object.keys(geoDataProcessed),
      datasets: [
        {
          label: "Leads",
          data: Object.values(geoDataProcessed).map((item) => item.leads),
          backgroundColor: [
            "#D91E18",
            "#E67E23",
            "#F9BF3B",
            "#2FCC71",
            "#1BB5FE",
            "#1F3A93",
            "#F62496",
            "#9A13B3",
            "#95411B",
            "#AEB8B8",
          ],
          hoverOffset: 4,
        },
      ],
    };
  }, [geoDataProcessed]);

  const handleBuyerChange = (buyer) => {
    setSelectedBuyers((prev) =>
      prev.includes(buyer) ? prev.filter((b) => b !== buyer) : [...prev, buyer]
    );
  };

  const handleUnselectAll = () => {
    setSelectedBuyers([]);
  };

  const handleSelectAll = () => {
    setSelectedBuyers(allBuyers);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      sortComparator: (v1, v2, params1, params2) => {
        if (params1.id === "total" || params2.id === "total") return 0;
        return v1.localeCompare(v2);
      },
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "leads",
      headerName: "Leads",
      flex: 1,
      sortComparator: (v1, v2, params1, params2) => {
        if (params1.id === "total" || params2.id === "total") return 0;
        return v1 - v2;
      },
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "deposit",
      headerName: "Deposits",
      flex: 1,
      sortComparator: (v1, v2, params1, params2) => {
        if (params1.id === "total" || params2.id === "total") return 0;
        return v1 - v2;
      },
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "cr",
      headerName: "CR (%)",
      flex: 1,
      renderCell: (params) => `${params.value}%`,
      sortComparator: (v1, v2, params1, params2) => {
        if (params1.id === "total" || params2.id === "total") return 0;
        return parseFloat(v1) - parseFloat(v2);
      },
      filterOperators: stringOnlyContainsOperators,
    },
  ];

  const handleGeoDialogOpen = (geo) => {
    const filteredLeads = leads.filter((lead) => {
      const buyer = lead.custom4 || "Unknown";
      return (
        lead.countryCode === geo.countryCode &&
        selectedBuyers.includes(buyer) &&
        (role !== "tlead" || (team && team.includes(buyer)))
      );
    });

    const filteredConversions = conversions.filter((conversion) => {
      const buyer = conversion.custom4 || "Unknown";
      return (
        conversion.countryCode === geo.countryCode &&
        selectedBuyers.includes(buyer) &&
        (role !== "tlead" || (team && team.includes(buyer)))
      );
    });

    const buyersData = filteredLeads.reduce((acc, lead) => {
      const buyer = lead.custom4 || "Unknown";
      if (!acc[buyer]) acc[buyer] = { leads: 0, deposits: 0 };
      acc[buyer].leads += 1;
      return acc;
    }, {});

    filteredConversions.forEach((conversion) => {
      const buyer = conversion.custom4 || "Unknown";
      if (!buyersData[buyer]) buyersData[buyer] = { leads: 0, deposits: 0 };
      buyersData[buyer].deposits += 1;
    });

    const rows = Object.keys(buyersData).map((buyer) => ({
      id: buyer,
      buyer,
      leads: buyersData[buyer].leads,
      deposits: buyersData[buyer].deposits,
      cr:
        buyersData[buyer].leads > 0
          ? ((buyersData[buyer].deposits / buyersData[buyer].leads) * 100).toFixed(2)
          : "0.00",
    }));

    setDialogRows(rows);
    setSelectedGeo(geo);
    setGeoDialogOpen(true);
  };

  const handleGeoDialogClose = () => {
    setGeoDialogOpen(false);
    setSelectedGeo(null);
  };

  const geoColumns = [
    {
      field: "countryCode",
      headerName: "Country Code",
      flex: 1,
      sortComparator: (v1, v2, params1, params2) => {
        if (params1.id === "geo-total" || params2.id === "geo-total") return 0;
        return v1.localeCompare(v2);
      },
      filterOperators: stringOnlyContainsOperators,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: 'space-between', height: '100%' }}>
          <Typography sx={{ marginRight: "8px", fontWeight: '700' }}>{params.value}</Typography>
          {params.id !== "geo-total" && (
            <IconButton
              size="small"
              color="primary"
              onClick={() => handleGeoDialogOpen(params.row)}
            >
              <InfoIcon />
            </IconButton>
          )}
        </Box>
      ),

    },
    {
      field: "leads",
      headerName: "Leads",
      flex: 1,
      sortComparator: (v1, v2, params1, params2) => {
        if (params1.id === "geo-total" || params2.id === "geo-total") return 0;
        return v1 - v2;
      },
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "deposit",
      headerName: "Deposits",
      flex: 1,
      sortComparator: (v1, v2, params1, params2) => {
        if (params1.id === "geo-total" || params2.id === "geo-total") return 0;
        return v1 - v2;
      },
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "cr",
      headerName: "CR (%)",
      flex: 1,
      renderCell: (params) => `${params.value}%`,
      sortComparator: (v1, v2, params1, params2) => {
        if (params1.id === "geo-total" || params2.id === "geo-total") return 0;
        return parseFloat(v1) - parseFloat(v2);
      },
      filterOperators: stringOnlyContainsOperators,
    },
  ];

  const setToday = () => {
    setFromDate(dayjs().startOf("day"));
    setToDate(dayjs().endOf("day"));
  };

  const setYesterday = () => {
    setFromDate(dayjs().subtract(1, "day").startOf("day"));
    setToDate(dayjs().subtract(1, "day").endOf("day"));
  };

  const setLastWeek = () => {
    setFromDate(dayjs().subtract(1, "week").startOf("week"));
    setToDate(dayjs().subtract(1, "week").endOf("week"));
  };

  const setLastMonth = () => {
    setFromDate(dayjs().subtract(1, "month").startOf("month"));
    setToDate(dayjs().subtract(1, "month").endOf("month"));
  };

  const setThisWeek = () => {
    setFromDate(dayjs().startOf("week"));
    setToDate(dayjs().endOf("week"));
  };

  const setThisMonth = () => {
    setFromDate(dayjs().startOf("month"));
    setToDate(dayjs().endOf("month"));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{position: 'relative'}}>
          {isMobile && (
          <IconButton
            onClick={fetchData}
            sx={{
              position: 'fixed',
              left: '12px',
              bottom: '12px',
              zIndex: 999999,
              backgroundColor: '#0BDF79',
              color: '#FFFFFF',
              width: '56px',
              height: '56px',
              borderRadius: '50%',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              '&:hover': {
                backgroundColor: '#0ACF6F',
              },
            }}
          >
            <RefreshIcon />
          </IconButton>
        )}
        <Typography variant="h4" align="center" gutterBottom sx={{margin: '0 0 40px 0'}}>
          Media Buyer Analytics
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "flex-start", flexDirection: isMobile ? "column" : "row", }}>
          <DatePicker
            label="From Date"
            value={fromDate}
            onChange={(date) => setFromDate(date)}
            format="DD/MM/YYYY"
          />
          <Divider
            orientation={isMobile ? "horizontal" : "vertical"}
            variant="middle"
            flexItem
            sx={{ margin: isMobile ? '24px 0' : "0 12px" }}
          />
          <DatePicker
            label="To Date"
            value={toDate}
            onChange={(date) => setToDate(date)}
            format="DD/MM/YYYY"
          />
          {!isMobile && (
          <IconButton
          onClick={fetchData}
          color="primary"
          sx={{ marginLeft: "12px" }}
          >
          <RefreshIcon />
          </IconButton>
          )}
        </Box>

        <Box sx={{ display: "flex", gap: isMobile ? 2 : 6, mb: 2, mt: 2, flexDirection: isMobile ? "column" : "row", }}>
          <Button onClick={setToday} variant="outlined" size={isMobile ? "large" : "small"}>
            Today
          </Button>
          <Button onClick={setYesterday} variant="outlined" size={isMobile ? "large" : "small"}>
            Yesterday
          </Button>
          <Button onClick={setLastWeek} variant="outlined" size={isMobile ? "large" : "small"}>
            Last Week
          </Button>
          <Button onClick={setLastMonth} variant="outlined" size={isMobile ? "large" : "small"}>
            Last Month
          </Button>
          <Button onClick={setThisWeek} variant="outlined" size={isMobile ? "large" : "small"}>
            This Week
          </Button>
          <Button onClick={setThisMonth} variant="outlined" size={isMobile ? "large" : "small"}>
            This Month
          </Button>
        </Box>

        <Typography variant="h5" gutterBottom>
          Filter by Media Buyer
        </Typography>

        <FormGroup row sx={{ margin: "0 0 12px 0" }}>
          <Button
            onClick={handleUnselectAll}
            variant="outlined"
            color="primary"
            sx={{ marginRight: "16px" }}
          >
            Unselect All
          </Button>
          <Button
            onClick={handleSelectAll}
            variant="outlined"
            color="primary"
            sx={{ marginRight: "16px" }}
          >
            Select All
          </Button>
          {allBuyers.map((buyer) => (
            <FormControlLabel
              key={buyer}
              control={
                <Checkbox
                  checked={selectedBuyers.includes(buyer)}
                  onChange={() => handleBuyerChange(buyer)}
                  color="primary"
                />
              }
              label={buyer}
            />
          ))}
        </FormGroup>

        {loading ? (
          <Loader />
        ) : (
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 25]}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0
                ? "MuiDataGrid-even-row"
                : ""
            }
            sx={dataGridStyles}
          />
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "space-between",
            width: "100%",
            mt: 4,
          }}
        >
          <Box sx={{ width: isMobile ? "100%" : "50%", mb: 2 }}>
            <Typography variant="h5" align="center" gutterBottom>
              Geo Analytics
            </Typography>
            <DataGrid
              rows={geoDataRows}
              columns={geoColumns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0
                  ? "MuiDataGrid-even-row"
                  : ""
              }
              sx={dataGridStyles}
            />
          </Box>

          <Box sx={{ width: isMobile ? "100%" : "45%", mt: isMobile ? 2 : 0 }}>
            <Typography variant="h5" align="center" gutterBottom>
              Leads by Geo
            </Typography>
            <Doughnut data={chartData} />
          </Box>
        </Box>
      </Box>
      <Dialog open={geoDialogOpen} onClose={handleGeoDialogClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {selectedGeo?.countryCode} - Buyers Details
        <IconButton
          aria-label="close"
          onClick={handleGeoDialogClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" gutterBottom>
          Media Buyers for {selectedGeo?.countryCode}
        </Typography>
        <DataGrid
          rows={dialogRows}
          columns={[
            { field: "buyer", headerName: "Buyer", flex: 1 },
            { field: "leads", headerName: "Leads", flex: 1 },
            { field: "deposits", headerName: "Deposits", flex: 1 },
            { field: "cr", headerName: "CR (%)", flex: 1 },
          ]}
          pageSize={5}
          rowsPerPageOptions={[5, 10]}
          sx={dataGridStyles}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleGeoDialogClose} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
    </LocalizationProvider>
  );
};

export default MediaBuyerAnalytics;
