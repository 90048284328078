import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { DataGrid, getGridStringOperators } from "@mui/x-data-grid";
import {
  TextField,
  Box,
  Typography,
  Divider,
  Select,
  MenuItem,
  FormControl,
  Button,
  useMediaQuery,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import axios from "axios";
import { Doughnut, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";
import Loader from "../components/Loader";
import { dataGridStyles } from "../styles";
import RefreshIcon from "@mui/icons-material/Refresh";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import { getUserInfoFromToken } from "../services/authService";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
);

const AccountsAnalytics = () => {
  const token = localStorage.getItem('authToken');
  const userInfo = useMemo(() => getUserInfoFromToken(), []);
  const { role, username, team } = userInfo;

  const [accountsData, setAccountsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [financeData, setFinanceData] = useState([]);
  const [loadingFinance, setLoadingFinance] = useState(true);

  const [searchEmail, setSearchEmail] = useState("");
  const [searchId, setSearchId] = useState("");
  const [searchDomain, setSearchDomain] = useState("");
  const [startDate, setStartDate] = useState(dayjs().startOf("day"));
  const [endDate, setEndDate] = useState(dayjs().endOf("day"));

  const [lifetimeFilter, setLifetimeFilter] = useState("");
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [columnWidths, setColumnWidths] = useState({});
  const [sellerFilter, setSellerFilter] = useState("");
  const [projectFilter, setProjectFilter] = useState("");
  const [isMetricsVisible, setIsMetricsVisible] = useState(false);
  const [selectedSeller, setSelectedSeller] = useState(null);
  const [accountTypeFilter, setAccountTypeFilter] = useState([]);
  const [cloFilter, setCloFilter] = useState([]);
  const [offerFilter, setOfferFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [totalCostRange, setTotalCostRange] = useState("");
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const [isExcludedFilterActive, setIsExcludedFilterActive] = useState(false);
  const [accountGeoFilter, setAccountGeoFilter] = useState([]);
  const [trafficGeoFilter, setTrafficGeoFilter] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [statusHistory, setStatusHistory] = useState(null);
  const [dialogError, setDialogError] = useState(null);
  const [openDailyDialog, setOpenDailyDialog] = useState(false);
  const [dailyDialogData, setDailyDialogData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [openTotalDialog, setOpenTotalDialog] = useState(false);

  const isMobile = useMediaQuery("(max-width:600px)");
  const tableName = "AccountsAnalytics";

  const EXCLUDED_STATUSES = [
    "компрометирующий сайт",
    "обход системы",
    "бизнес модель",
    "бизнес практика",
    "модерация (вайт/блэк)",
    "модерация (вайт/вайт)",
  ];

  const RED_STATUSES = [
    "обход системы",
    "бизнес модель",
    "бизнес практика",
    "компрометирующий сайт",
  ];

  const stringOnlyContainsOperators = getGridStringOperators().filter(
    (operator) => operator.value === "contains"
  );

  const dataGridStylesSticky = {
    height: 600,
    "& .MuiDataGrid-columnHeaders": {
      position: "sticky",
      top: 0,
      zIndex: 1000,
    },
    "& .MuiDataGrid-virtualScroller": {
      overflowY: "auto",
    },
  };

  const toggleAdvancedSettings = useCallback(() => {
    setShowAdvancedSettings((prev) => !prev);
  }, []);

  const handleOpenDialog = useCallback(async (domain) => {
    try {
      setDialogError(null);
      setStatusHistory(null);
      setOpenDialog(true);
      const response = await axios.get(
        `https://backend.moorpan.com/get-status-history/${domain}`, {headers: {
          Authorization: `Bearer ${token}`
        }}
      );
      setStatusHistory(response.data);
    } catch (error) {
      setDialogError(error.response?.data?.message || "Ошибка загрузки данных");
    }
  }, []);

  const handleCloseDialog = useCallback(() => {
    setOpenDialog(false);
  }, []);

  const fetchAccountsData = useCallback(async () => {
    setLoading(true);
    try {
      const params = {
        startDate: startDate ? startDate.toISOString() : undefined,
        endDate: endDate ? endDate.toISOString() : undefined,
      };

      if (role === "user" && username) {
        params.username = username;
        params.role = role;
      } else if (role === "tlead" && Array.isArray(team)) {
        params.username = username;
        params.role = role;
      }

      const response = await axios.get("https://backend.moorpan.com/get-accounts", {
        params,
        headers: { role, username, Authorization: `Bearer ${token}` },
      });

      let accounts = response.data;
      if (role === "tlead" && Array.isArray(team)) {
        accounts = accounts.filter((account) => team.includes(account.project));
      }

      setAccountsData(accounts);
    } catch (err) {
      setError("Ошибка при загрузке данных");
    } finally {
      setLoading(false);
    }
  }, [startDate, endDate, role, username, team]);

  const fetchColumnSettings = useCallback(async () => {
    try {
      const response = await axios.get(
        "https://backend.moorpan.com/get-column-settings",
        { params: { username, tableName, Authorization: `Bearer ${token}` } }
      );

      if (response.data) {
        const visibilityModel = response.data.reduce((acc, col) => {
          acc[col.field] = !col.hide;
          return acc;
        }, {});

        const widths = response.data.reduce((acc, col) => {
          if (col.width) acc[col.field] = col.width;
          return acc;
        }, {});

        setColumnVisibilityModel(visibilityModel);
        setColumnWidths(widths);
      }
    } catch (error) {
      console.error("Error fetching column settings:", error);
    }
  }, [username, tableName]);

  const handleColumnWidthChange = useCallback(
    async (params) => {
      const updatedWidths = { ...columnWidths, [params.colDef.field]: params.width };
      setColumnWidths(updatedWidths);

      try {
        await axios.post("https://backend.moorpan.com/save-column-settings", {
          username,
          tableName,
          columnConfig: Object.keys(updatedWidths).map((field) => ({
            field,
            width: updatedWidths[field],
            hide: false,
          })),
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        console.log("Column widths saved.");
      } catch (error) {
        console.error("Error saving column widths:", error);
      }
    },
    [username, tableName, columnWidths]
  );

  const handleColumnVisibilityModelChange = useCallback(
    async (newVisibilityModel) => {
      setColumnVisibilityModel(newVisibilityModel);

      const columnConfig = baseColumns.map((col) => ({
        field: col.field,
        hide: !newVisibilityModel[col.field],
        width: columnWidths[col.field] || col.minWidth,
      }));

      try {
        await axios.post("https://backend.moorpan.com/save-column-settings", {
          username,
          tableName,
          columnConfig,
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        console.log("Column visibility saved.");
      } catch (error) {
        console.error("Error saving column visibility:", error);
      }
    },
    // eslint-disable-next-line
    [username, tableName, columnWidths, /* baseColumns */]
  );

  const isUser = role === "user";
  const isTlead = role === "tlead";

  const fetchFinanceData = useCallback(async () => {
    setLoadingFinance(true);
    try {
      const response = await axios.get("https://backend.moorpan.com/finance", {
        headers: {
          role: role,
          username: username,
          team: team ? JSON.stringify(team) : "[]",
          Authorization: `Bearer ${token}`
        },
      });

      let processedData = response.data.map((row, index) => ({
        ...row,
        id_account: row.id_account || `finance-${index}`,
      }));

      if (isUser) {
        processedData = processedData.filter((r) => r.buyer === username);
      } else if (isTlead) {
        processedData = processedData.filter(
          (r) => r.buyer === username || (team && team.includes(r.buyer))
        );
      }

      setFinanceData(processedData);
    } catch (error) {
      console.error("Ошибка при загрузке финансовых данных:", error);
    } finally {
      setLoadingFinance(false);
    }
  }, [role, username, team, isUser, isTlead]);

  useEffect(() => {
    if (startDate && endDate) {
      fetchAccountsData();
      fetchFinanceData();
    }
  }, [startDate, endDate, fetchAccountsData, fetchFinanceData]);

  useEffect(() => {
    fetchColumnSettings();
  }, [fetchColumnSettings]);

  const baseColumns = useMemo(
    () => [
      {
        field: "dateOfIssue",
        headerName: "Data of issue",
        minWidth: 60,
        valueFormatter: (params) =>
          params ? dayjs(params).format("DD/MM/YYYY") : "",
        sortable: true,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: 60,
        sortable: false,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "seller",
        headerName: "Seller",
        minWidth: 60,
        sortable: false,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "accountType",
        headerName: "Account Type",
        minWidth: 60,
        sortable: false,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "project",
        headerName: "Buyer",
        minWidth: 60,
        sortable: false,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "idAccount",
        headerName: "ID Account",
        minWidth: 60,
        sortable: false,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "clo",
        headerName: "CLO",
        minWidth: 60,
        sortable: false,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "accountGeo",
        headerName: "Account Geolocation",
        minWidth: 60,
        sortable: false,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "mccOrganizationName",
        headerName: "MCC Organization Name",
        minWidth: 60,
        sortable: false,
      },
      {
        field: "paymentMethodOrIDMCC",
        headerName: "Payment Method or ID MCC",
        minWidth: 60,
        sortable: false,
      },
      {
        field: "domain",
        headerName: "Domain",
        minWidth: 60,
        sortable: false,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "offer",
        headerName: "Offer",
        minWidth: 60,
        sortable: false,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "statusAds",
        headerName: "Status Ads",
        minWidth: 60,
        sortable: false,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "trafficGeo",
        headerName: "Traffic GEO",
        minWidth: 60,
        sortable: false,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "dayliSpend",
        headerName: "Dayli Spend",
        minWidth: 60,
        sortable: false,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "totalLeads",
        headerName: "Total Leads",
        minWidth: 60,
        sortable: true,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "totalDeposit",
        headerName: "Total Deposit",
        minWidth: 60,
        sortable: true,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "totalCost",
        headerName: "Total Cost",
        minWidth: 60,
        sortable: true,
        filterOperators: stringOnlyContainsOperators,
        sortComparator: (v1, v2) => {
          const num1 = !isNaN(parseFloat(v1)) ? parseFloat(v1) : 0;
          const num2 = !isNaN(parseFloat(v2)) ? parseFloat(v2) : 0;
          return num1 - num2;
        },
      },
      {
        field: "cpl",
        headerName: "CPL",
        minWidth: 60,
        valueFormatter: (params) => {
          if (!params || isNaN(Number(params))) return 0;
          return Number(params).toFixed(2);
        },
        sortable: true,
        filterOperators: stringOnlyContainsOperators,
        sortComparator: (v1, v2) => {
          const num1 = !isNaN(Number(v1)) && v1 !== "N/A" ? Number(v1) : 0;
          const num2 = !isNaN(Number(v2)) && v2 !== "N/A" ? Number(v2) : 0;
          return num1 - num2;
        },
      },
      {
        field: "lifeDuration",
        headerName: "Life Duration Account (days)",
        minWidth: 60,
        renderCell: (params) =>
          params.value != null ? params.value : "Нет данных",
        sortable: true,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "lifetimeAccount",
        headerName: "Life Duration Account Full (days)",
        minWidth: 60,
        headerClassName: "lifetime-account-header",
        cellClassName: "lifetime-account-cell",
        sortable: true,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "lifetimeDomain",
        headerName: "Lifetime Domain Full (days)",
        minWidth: 60,
        headerClassName: "lifetime-domain-header",
        cellClassName: "lifetime-domain-cell",
        sortable: true,
        filterOperators: stringOnlyContainsOperators,
      },
    ],
    [stringOnlyContainsOperators]
  );

  const mergedAccounts = useMemo(() => {

    if (!financeData || financeData.length === 0) {
      return accountsData;
    }

    return accountsData.map((accRow) => {
      const costForAccount = financeData
        .filter((finRow) => finRow.id_account === accRow.idAccount)
        .reduce((sum, fin) => parseFloat(sum) + (parseFloat(fin.cost) || 0), 0);

      return {
        ...accRow,
        totalCost: parseFloat(costForAccount).toFixed(2),
      };
    });
  }, [accountsData, financeData]);

  const filteredData = useMemo(() => {
    return mergedAccounts
      .map((row, index) => ({
        ...row,
        id: `${row.email}-${index}`,
        seller: row.seller || "Unknown",
      }))
      .filter((row) => {
        const matchesEmail = row.email
          ? row.email.toLowerCase().includes(searchEmail.toLowerCase())
          : false;
        const matchesId = row.idAccount
          ? row.idAccount.includes(searchId)
          : false;
        const matchesDomain = row.domain
          ? row.domain.toLowerCase().includes(searchDomain.toLowerCase())
          : false;
        const matchesDate =
          (!startDate ||
            dayjs(row.dateOfIssue).isSameOrAfter(dayjs(startDate).startOf("day"))) &&
          (!endDate ||
            dayjs(row.dateOfIssue).isSameOrBefore(dayjs(endDate).endOf("day")));

        const matchesLifetime =
          lifetimeFilter === "" ||
          (lifetimeFilter === "0-7" && row.lifetimeAccount >= 0 && row.lifetimeAccount <= 7) ||
          (lifetimeFilter === "7-14" && row.lifetimeAccount > 7 && row.lifetimeAccount <= 14) ||
          (lifetimeFilter === "14-31" && row.lifetimeAccount > 14 && row.lifetimeAccount <= 31) ||
          (lifetimeFilter === "31+" && row.lifetimeAccount > 31);

        const matchesSeller =
          sellerFilter === "Unknown"
            ? row.seller === "Unknown"
            : sellerFilter === "" || row.seller === sellerFilter;

        const matchesProject = projectFilter === "" || row.project === projectFilter;

        const matchesAccountType =
          accountTypeFilter.length === 0 ||
          accountTypeFilter.includes(row.accountType);

        const matchesClo =
          cloFilter.length === 0 || cloFilter.includes(row.clo);

        const matchesOffer =
          offerFilter.length === 0 || offerFilter.includes(row.offer);

        const matchesStatus =
          statusFilter.length === 0 || statusFilter.includes(row.statusAds);

        const matchesTotalCost =
          totalCostRange === "" ||
          (totalCostRange === "0-500" && row.totalCost <= 500) ||
          (totalCostRange === "500-999" && row.totalCost > 500 && row.totalCost <= 999) ||
          (totalCostRange === "1000+" && row.totalCost >= 1000);

        const matchesAccountGeo =
          accountGeoFilter.length === 0 || accountGeoFilter.includes(row.accountGeo);
        const matchesTrafficGeo =
          trafficGeoFilter.length === 0 || trafficGeoFilter.includes(row.trafficGeo);

        return (
          matchesEmail &&
          matchesId &&
          matchesDomain &&
          matchesDate &&
          matchesLifetime &&
          matchesSeller &&
          matchesProject &&
          matchesAccountType &&
          matchesClo &&
          matchesOffer &&
          matchesStatus &&
          matchesTotalCost &&
          matchesAccountGeo &&
          matchesTrafficGeo
        );
      });
  }, [
    mergedAccounts,
    searchEmail,
    searchId,
    searchDomain,
    startDate,
    endDate,
    lifetimeFilter,
    sellerFilter,
    projectFilter,
    accountTypeFilter,
    cloFilter,
    offerFilter,
    statusFilter,
    totalCostRange,
    accountGeoFilter,
    trafficGeoFilter,
  ]);

  const handleOpenDailyDialog = useCallback(
    (date) => {
      setSelectedDate(date);

      const filteredDataBySeller = filteredData.filter(
        (account) =>
          dayjs(account.dateOfIssue).format("YYYY-MM-DD") === date &&
          account.seller === selectedSeller
      );

      const groupedData = filteredDataBySeller.reduce((acc, account) => {
        const project = account.project || "Unknown";
        if (!acc[project]) {
          acc[project] = { project, totalCost: 0, seller: selectedSeller };
        }
        acc[project].totalCost += account.totalCost || 0;
        return acc;
      }, {});

      const dialogData = Object.values(groupedData).map((item) => ({
        id: `${selectedSeller}-${item.project}`,
        seller: item.seller,
        project: item.project,
        totalCost: item.totalCost.toFixed(2),
      }));

      setDailyDialogData(dialogData);
      setOpenDailyDialog(true);
    },
    [filteredData, selectedSeller]
  );

  const handleCloseDailyDialog = useCallback(() => {
    setOpenDailyDialog(false);
    setDailyDialogData([]);
  }, []);

  const columnsWithDetails = useMemo(
    () => [
      {
        field: "seller",
        headerName: "Seller",
        minWidth: 200,
        flex: 1,
        sortable: true,
        sortComparator: (v1, v2, params1, params2) => {
          if (params1.id === "total" || params2.id === "total") return 0;
          return v1.localeCompare(v2);
        },
      },
      {
        field: "totalCost",
        headerName: "Total Cost",
        minWidth: 150,
        flex: 1,
        sortable: true,
        sortComparator: (v1, v2, params1, params2) => {
          if (params1.id === "total" || params2.id === "total") return 0;
          return v1 - v2;
        },
      },
      {
        field: "details",
        headerName: "Details",
        minWidth: 150,
        sortable: false,
        renderCell: (params) =>
          params.row.seller !== "Total" ? (
            <Button
              variant="outlined"
              size="small"
              onClick={() =>
                setSelectedSeller((prev) =>
                  prev === params.row.seller ? null : params.row.seller
                )
              }
            >
              {selectedSeller === params.row.seller ? "Hide" : "Details"}
            </Button>
          ) : null,
      },
    ],
    [selectedSeller]
  );

  // Daily columns
  const dailyColumns = useMemo(
    () => [
      {
        field: "date",
        headerName: "Date",
        minWidth: 150,
        flex: 1,
        sortable: true,
        valueFormatter: (params) => {
          return params === "Total"
            ? "Total"
            : dayjs(params).format("DD.MM.YYYY");
        },
        sortComparator: (v1, v2, params1, params2) => {
          if (params1.id === "total" || params2.id === "total") return 0;
          return new Date(v1) - new Date(v2);
        },
        renderCell: (params) => {
          if (!params.value) return null;
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "100%",
                width: "100%",
              }}
            >
              <Typography variant="body2" sx={{ marginRight: "8px", fontWeight: "700" }}>
                {params.value === "Total"
                  ? "Total"
                  : dayjs(params.value).format("DD.MM.YYYY")}
              </Typography>
              {params.value !== "Total" && (
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => handleOpenDailyDialog(params.row.date)}
                >
                  <InfoIcon />
                </IconButton>
              )}
            </Box>
          );
        },
      },
      {
        field: "totalCost",
        headerName: "Total Cost",
        minWidth: 150,
        flex: 1,
        sortable: true,
        sortComparator: (v1, v2, params1, params2) => {
          if (params1.id === "total" || params2.id === "total") return 0;
          return v1 - v2;
        },
      },
    ],
    [handleOpenDailyDialog]
  );

  const totalCostBySeller = useMemo(() => {
    const costData = {};
    filteredData.forEach((account) => {
      const seller = account.seller || "Unknown";
      const cost = parseFloat(account.totalCost) || 0;
      if (!costData[seller]) {
        costData[seller] = 0;
      }
      costData[seller] += cost;
    });

    return Object.keys(costData).map((seller) => ({
      id: seller,
      seller,
      totalCost: Number(costData[seller]).toFixed(2),
    }));
  }, [filteredData]);

  const totalCostSum = useMemo(() => {
    return totalCostBySeller
      .reduce((sum, item) => sum + parseFloat(item.totalCost), 0)
      .toFixed(2);
  }, [totalCostBySeller]);

  const rowsWithTotal = useMemo(() => {
    return [
      ...totalCostBySeller,
      { id: "total", seller: "Total", totalCost: totalCostSum },
    ];
  }, [totalCostBySeller, totalCostSum]);

  const sellerData = useMemo(() => {
    const data = {};
    filteredData.forEach((account) => {
      const date = dayjs(account.dateOfIssue).format("YYYY-MM-DD");
      if (!data[date]) {
        data[date] = { totalLeads: 0, totalCost: 0, totalDeposit: 0, cpl: 0 };
      }
      data[date].totalLeads += account.totalLeads || 0;
      data[date].totalCost += account.totalCost || 0;
      data[date].totalDeposit += account.totalDeposit || 0;
      data[date].cpl += parseFloat(account.cpl) || 0;
    });
    return data;
  }, [filteredData]);

  const sellerDailyData = useMemo(() => {
    if (!selectedSeller) return [];

    const dailyData = {};
    filteredData.forEach((account) => {
      if (account.seller === selectedSeller) {
        const date = dayjs(account.dateOfIssue).format("YYYY-MM-DD");
        if (!dailyData[date]) {
          dailyData[date] = { date, totalCost: 0 };
        }
        dailyData[date].totalCost += account.totalCost || 0;
      }
    });

    const rows = Object.values(dailyData).map((item, index) => ({
      id: `${item.date}-${index}`,
      ...item,
      totalCost: item.totalCost.toFixed(2),
    }));

    const totalSum = rows
      .reduce((sum, row) => sum + parseFloat(row.totalCost), 0)
      .toFixed(2);

    rows.push({ id: "total", date: "Total", totalCost: totalSum });
    return rows;
  }, [filteredData, selectedSeller]);

  const chartDataDoughnut = useMemo(() => {
    const dataBySeller = {};
    filteredData.forEach((account) => {
      const seller = account.seller || "Unknown";
      if (!dataBySeller[seller]) dataBySeller[seller] = 0;
      dataBySeller[seller] += 1;
    });
    const labels = Object.keys(dataBySeller);
    const data = Object.values(dataBySeller);
    const backgroundColors = [
      "#FF6384",
      "#36A2EB",
      "#FFCE56",
      "#4BC0C0",
      "#9966FF",
      "#FF9F40",
      "#66D9EF",
      "#E57373",
      "#81C784",
      "#FFD700",
    ];

    return {
      labels,
      datasets: [
        {
          label: "Accounts by Seller",
          data,
          backgroundColor: backgroundColors.slice(0, labels.length),
        },
      ],
    };
  }, [filteredData]);

  const chartOptions = useMemo(
    () => ({
      plugins: {
        tooltip: {
          callbacks: {
            label: (tooltipItem) => {
              const seller = tooltipItem.label;
              const totalAccounts = filteredData.filter(
                (account) => account.seller === seller
              ).length;

              const buckets = filteredData.reduce(
                (acc, account) => {
                  if (account.seller === seller) {
                    const lifetime = account.lifetimeAccount || 0;
                    if (lifetime >= 0 && lifetime <= 7) {
                      acc["0-7"] += 1;
                    } else if (lifetime > 7 && lifetime <= 14) {
                      acc["7-14"] += 1;
                    } else if (lifetime > 14 && lifetime <= 31) {
                      acc["14-31"] += 1;
                    } else if (lifetime > 31) {
                      acc["31+"] += 1;
                    }
                  }
                  return acc;
                },
                { "0-7": 0, "7-14": 0, "14-31": 0, "31+": 0 }
              );

              return [
                `Seller: ${seller}`,
                `Total Accounts: ${totalAccounts}`,
                `0-7 days: ${buckets["0-7"]}`,
                `7-14 days: ${buckets["7-14"]}`,
                `14-31 days: ${buckets["14-31"]}`,
                `31+ days: ${buckets["31+"]}`,
              ];
            },
          },
        },
      },
    }),
    [filteredData]
  );

  const chartDataLine = useMemo(() => {
    const sortedDates = Object.keys(sellerData).sort(
      (a, b) => new Date(a) - new Date(b)
    );
    const leadsData = sortedDates.map((date) => sellerData[date].totalLeads);

    return {
      labels: sortedDates,
      datasets: [
        {
          label: "Total Leads",
          data: leadsData,
          borderColor: "#36A2EB",
          backgroundColor: "rgba(54, 162, 235, 0.2)",
          fill: true,
          tension: 0.4,
          pointRadius: 12,
          pointHoverRadius: 16,
        },
      ],
    };
  }, [sellerData]);

  const lineOptions = useMemo(
    () => ({
      plugins: {
        tooltip: {
          callbacks: {
            label: (tooltipItem) => {
              const date = tooltipItem.label;
              const data = sellerData[date];
              const cpl =
                isNaN(parseFloat(data?.cpl)) ||
                data?.cpl.toString().includes("#DIV/0")
                  ? "N/A"
                  : data.cpl;

              return [
                `Date: ${date}`,
                `Total Cost: ${data?.totalCost || 0}`,
                `Total Deposit: ${data?.totalDeposit || 0}`,
                `Total Leads: ${data?.totalLeads || 0}`,
                `CPL: ${cpl}`,
              ];
            },
          },
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: "Date",
          },
        },
        y: {
          title: {
            display: true,
            text: "Total Leads",
          },
        },
      },
    }),
    [sellerData]
  );

  const setToday = useCallback(() => {
    setStartDate(dayjs().startOf("day"));
    setEndDate(dayjs().endOf("day"));
  }, []);

  const setYesterday = useCallback(() => {
    setStartDate(dayjs().subtract(1, "day").startOf("day"));
    setEndDate(dayjs().subtract(1, "day").endOf("day"));
  }, []);

  const setLastWeek = useCallback(() => {
    setStartDate(dayjs().subtract(1, "week").startOf("week"));
    setEndDate(dayjs().subtract(1, "week").endOf("week"));
  }, []);

  const setLastMonth = useCallback(() => {
    setStartDate(dayjs().subtract(1, "month").startOf("month"));
    setEndDate(dayjs().subtract(1, "month").endOf("month"));
  }, []);

  const setThisWeek = useCallback(() => {
    setStartDate(dayjs().startOf("week"));
    setEndDate(dayjs().endOf("week"));
  }, []);

  const setThisMonth = useCallback(() => {
    setStartDate(dayjs().startOf("month"));
    setEndDate(dayjs().endOf("month"));
  }, []);

  const setLastThreeMonths = useCallback(() => {
    setStartDate(dayjs().subtract(2, "month").startOf("day"));
    setEndDate(dayjs().endOf("day"));
  }, []);

  const handleExcludedStatusFilter = useCallback(() => {
    if (!isExcludedFilterActive) {
      const remainingStatuses = mergedAccounts
        .map((row) => row.statusAds)
        .filter((status) => !EXCLUDED_STATUSES.includes(status));
      setStatusFilter([...new Set(remainingStatuses)]);
    } else {
      setStatusFilter([]);
    }
    setIsExcludedFilterActive((prev) => !prev);
    // eslint-disable-next-line
  }, [isExcludedFilterActive, mergedAccounts]);

  const renderedColumns = useMemo(() => {
    return baseColumns.map((col) => {
      if (col.field === "statusAds") {
        return {
          ...col,
          renderCell: (params) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  marginRight: "8px",
                  color: "#1e1111",
                  fontWeight: "700",
                }}
              >
                {params.value}
              </Typography>
              <IconButton
                size="small"
                color="primary"
                onClick={() => handleOpenDialog(params.row.domain)}
              >
                <InfoIcon />
              </IconButton>
            </Box>
          ),
          width: columnWidths[col.field] || col.minWidth,
        };
      }
      return {
        ...col,
        width: columnWidths[col.field] || col.minWidth,
      };
    });
  }, [baseColumns, columnWidths, handleOpenDialog]);

  const downloadCSV = () => {
    const visibleCols = renderedColumns.filter(
      (col) => columnVisibilityModel[col.field] !== false
    );
    const headers = visibleCols.map((col) => col.headerName);

    const rows = filteredData.map((row) => {
      return visibleCols.map((col) => {
        const value = row[col.field];
        return value !== undefined && value !== null
          ? String(value).replace(/"/g, '""')
          : "";
      });
    });

    let csvContent = headers.map((h) => `${h}`).join(",") + "\n";
    rows.forEach((r) => {
      csvContent += r.map((cell) => `${cell}`).join(",") + "\n";
    });

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "accounts.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const totalStats = useMemo(() => {
    const totalAccounts = filteredData.length;

    const totalCostAll = filteredData.reduce(
      (sum, acc) => parseFloat(sum) + (parseFloat(acc.totalCost) || 0),
      0
    ).toFixed(2);
    const totalDepositAll = filteredData.reduce(
      (sum, acc) => sum + (acc.totalDeposit || 0),
      0
    );
    const totalLeadsAll = filteredData.reduce(
      (sum, acc) => sum + (acc.totalLeads || 0),
      0
    );

    const costRecords = filteredData.filter((acc) => (acc.totalCost || 0) > 0);

    const cplValues = costRecords.map((acc) => {
      const val = parseFloat(acc.cpl);
      return isNaN(val) ? 0 : val;
    });

    const cplSum = cplValues.reduce((s, v) => s + v, 0);
    const avgCpl = cplValues.length > 0 ? cplSum / cplValues.length : 0;

    return {
      totalAccounts,
      totalCostAll: parseFloat(totalCostAll).toFixed(2),
      totalLeadsAll: Number(totalLeadsAll),
      totalDepositAll: Number(totalDepositAll),
      avgCpl: avgCpl.toFixed(2),
    };
  }, [filteredData]);

  const handleOpenTotalDialog = () => {
    setOpenTotalDialog(true);
  };
  const handleCloseTotalDialog = () => {
    setOpenTotalDialog(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ position: "relative" }}>
        {loading || loadingFinance ? (
          <Loader />
        ) : error ? (
          <Typography variant="h6" color="error" align="center">
            {error}
          </Typography>
        ) : (
          <>
            {isMobile && (
              <IconButton
                onClick={() => {
                  fetchAccountsData();
                  fetchFinanceData();
                }}
                sx={{
                  position: "fixed",
                  left: "12px",
                  bottom: "12px",
                  zIndex: 999999,
                  backgroundColor: "#0BDF79",
                  color: "#FFFFFF",
                  width: "56px",
                  height: "56px",
                  borderRadius: "50%",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  "&:hover": {
                    backgroundColor: "#0ACF6F",
                  },
                }}
              >
                <RefreshIcon />
              </IconButton>
            )}

            <Typography
              variant="h4"
              align="center"
              gutterBottom
              sx={{ margin: "0 0 40px 0" }}
            >
              Accounts Analytics
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <DatePicker
                label="From Date"
                value={startDate}
                onChange={(date) => setStartDate(date)}
                format="DD/MM/YYYY"
              />
              <Divider
                orientation={isMobile ? "horizontal" : "vertical"}
                variant="middle"
                flexItem
                sx={{ margin: isMobile ? "24px 0" : "0 12px" }}
              />
              <DatePicker
                label="To Date"
                value={endDate}
                onChange={(date) => setEndDate(date)}
                format="DD/MM/YYYY"
              />
              {!isMobile && (
                <IconButton
                  onClick={() => {
                    fetchAccountsData();
                    fetchFinanceData();
                  }}
                  color="primary"
                  sx={{ marginLeft: "12px" }}
                >
                  <RefreshIcon />
                </IconButton>
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: isMobile ? 2 : 6,
                mb: 2,
                mt: 2,
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <Button
                onClick={setToday}
                variant="outlined"
                size={isMobile ? "large" : "small"}
              >
                Today
              </Button>
              <Button
                onClick={setYesterday}
                variant="outlined"
                size={isMobile ? "large" : "small"}
              >
                Yesterday
              </Button>
              <Button
                onClick={setLastWeek}
                variant="outlined"
                size={isMobile ? "large" : "small"}
              >
                Last Week
              </Button>
              <Button
                onClick={setLastMonth}
                variant="outlined"
                size={isMobile ? "large" : "small"}
              >
                Last Month
              </Button>
              <Button
                onClick={setThisWeek}
                variant="outlined"
                size={isMobile ? "large" : "small"}
              >
                This Week
              </Button>
              <Button
                onClick={setThisMonth}
                variant="outlined"
                size={isMobile ? "large" : "small"}
              >
                This Month
              </Button>
              <Button
                onClick={setLastThreeMonths}
                variant="outlined"
                size={isMobile ? "large" : "small"}
              >
                Last 3 months
              </Button>
            </Box>

            {filteredData.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <Box sx={{ margin: "0", width: isMobile ? "100%" : "30%" }}>
                  <Typography variant="h5" align="center" gutterBottom>
                    Accounts by Seller
                  </Typography>
                  <Doughnut data={chartDataDoughnut} options={chartOptions} />
                </Box>
                <Box sx={{ margin: "0", width: isMobile ? "100%" : "60%" }}>
                  <Typography variant="h5" align="center" gutterBottom>
                    Total Cost by Seller
                  </Typography>
                  <DataGrid
                    rows={rowsWithTotal}
                    columns={columnsWithDetails}
                    pageSize={5}
                    sx={dataGridStyles}
                    rowsPerPageOptions={[5, 10, 20]}
                    getRowClassName={(params) =>
                      params.id === "total"
                        ? "total-row"
                        : params.indexRelativeToCurrentPage % 2 === 0
                        ? "MuiDataGrid-even-row"
                        : ""
                    }
                  />
                  {selectedSeller && (
                    <Box sx={{ marginTop: 2 }}>
                      <Typography variant="h6" align="center" gutterBottom>
                        Daily Costs for {selectedSeller}
                      </Typography>
                      <DataGrid
                        rows={sellerDailyData}
                        columns={dailyColumns}
                        pageSize={5}
                        sx={dataGridStyles}
                        rowsPerPageOptions={[5, 10, 20]}
                        getRowClassName={(params) =>
                          params.id === "total"
                            ? "total-row"
                            : params.indexRelativeToCurrentPage % 2 === 0
                            ? "MuiDataGrid-even-row"
                            : ""
                        }
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            )}

            <Button
              onClick={() => setIsMetricsVisible((prev) => !prev)}
              variant="outlined"
              size="large"
              sx={{ marginBottom: 2, width: isMobile ? "100%" : "auto" }}
            >
              {isMetricsVisible ? "Hide Metrics Overview" : "Show Metrics Overview"}
            </Button>
            {isMetricsVisible && (
              <Box sx={{ margin: "0", width: "100%" }}>
                <Typography variant="h5" align="center" gutterBottom>
                  Accounts Metrics Overview
                </Typography>
                <Line data={chartDataLine} options={lineOptions} />
              </Box>
            )}

            <Typography variant="h5" gutterBottom sx={{ margin: "0 0 12px 0" }}>
              Filter by params
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
              <FormControl sx={{ width: "49%" }}>
                <Select
                  value={sellerFilter}
                  onChange={(e) => setSellerFilter(e.target.value)}
                  displayEmpty
                  size="small"
                  renderValue={(selected) =>
                    selected === "" ? "All Sellers" : selected
                  }
                >
                  <MenuItem value="">All Sellers</MenuItem>
                  {[
                    ...new Set(accountsData.map((account) => account.seller || "Unknown")),
                  ].map((seller) => (
                    <MenuItem key={seller} value={seller}>
                      {seller}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: "49%" }}>
                <Select
                  value={projectFilter}
                  onChange={(e) => setProjectFilter(e.target.value)}
                  displayEmpty
                  size="small"
                  renderValue={(selected) =>
                    selected === "" ? "All Buyers" : selected
                  }
                >
                  <MenuItem value="">All Buyers</MenuItem>
                  {[...new Set(accountsData.map((account) => account.project))].map(
                    (project) => (
                      <MenuItem key={project} value={project}>
                        {project}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
                mb: 2,
                justifyContent: "space-between",
              }}
            >
              <TextField
                label="Search by Email"
                value={searchEmail}
                onChange={(e) => setSearchEmail(e.target.value)}
                variant="outlined"
                size="small"
                sx={{ width: "32%" }}
              />

              <TextField
                label="Search by Account ID"
                value={searchId}
                onChange={(e) => setSearchId(e.target.value)}
                variant="outlined"
                size="small"
                sx={{ width: "32%" }}
              />

              <TextField
                label="Search by Domain"
                value={searchDomain}
                onChange={(e) => setSearchDomain(e.target.value)}
                variant="outlined"
                size="small"
                sx={{ width: "32%" }}
              />

              <Autocomplete
                multiple
                limitTags={2}
                options={[
                  ...new Set(accountsData.map((row) => row.accountGeo).filter(Boolean)),
                ]}
                value={accountGeoFilter}
                onChange={(event, newValue) => setAccountGeoFilter(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter by AccountGeo"
                    placeholder="All"
                    variant="outlined"
                    size="small"
                  />
                )}
                sx={{ width: "32%" }}
              />

              <Autocomplete
                multiple
                limitTags={2}
                options={[
                  ...new Set(accountsData.map((row) => row.trafficGeo).filter(Boolean)),
                ]}
                value={trafficGeoFilter}
                onChange={(event, newValue) => setTrafficGeoFilter(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter by TrafficGeo"
                    placeholder="All"
                    variant="outlined"
                    size="small"
                  />
                )}
                sx={{ width: "32%" }}
              />
            </Box>

            <Typography variant="h5" gutterBottom sx={{ margin: "0 0 12px 0" }}>
              Lifetime Account Filter
            </Typography>
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Select
                value={lifetimeFilter}
                onChange={(e) => setLifetimeFilter(e.target.value)}
                size="small"
                displayEmpty
                renderValue={(selected) => (selected === "" ? "All" : selected)}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="0-7">0-7 days</MenuItem>
                <MenuItem value="7-14">7-14 days</MenuItem>
                <MenuItem value="14-31">14-31 days</MenuItem>
                <MenuItem value="31+">31+ days</MenuItem>
              </Select>
            </FormControl>

            <Box>
              <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
                <Button
                  onClick={toggleAdvancedSettings}
                  variant="outlined"
                  size="small"
                >
                  {showAdvancedSettings
                    ? "Hide Additional Settings"
                    : "Show Additional Settings"}
                </Button>
                <Button
                  onClick={handleExcludedStatusFilter}
                  variant="outlined"
                  size="small"
                >
                  {isExcludedFilterActive
                    ? "Show All Data"
                    : "Filter Excluded Statuses"}
                </Button>
              </Box>

              {showAdvancedSettings && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    mb: 2,
                    justifyContent: "space-between",
                  }}
                >
                  <Autocomplete
                    multiple
                    limitTags={2}
                    options={[
                      ...new Set(
                        accountsData.map((account) => account.accountType).filter(Boolean)
                      ),
                    ]}
                    value={accountTypeFilter}
                    onChange={(event, newValue) => setAccountTypeFilter(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Account Type"
                        placeholder="Favorites"
                      />
                    )}
                    sx={{ width: "19%" }}
                  />

                  <Autocomplete
                    multiple
                    limitTags={2}
                    options={[
                      ...new Set(accountsData.map((account) => account.clo).filter(Boolean)),
                    ]}
                    value={cloFilter}
                    onChange={(event, newValue) => setCloFilter(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select CLO"
                        placeholder="Favorites"
                      />
                    )}
                    sx={{ width: "19%" }}
                  />

                  <Autocomplete
                    multiple
                    limitTags={2}
                    options={[
                      ...new Set(accountsData.map((account) => account.offer).filter(Boolean)),
                    ]}
                    value={offerFilter}
                    onChange={(event, newValue) => setOfferFilter(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Offer"
                        placeholder="Favorites"
                      />
                    )}
                    sx={{ width: "19%" }}
                  />

                  <Autocomplete
                    multiple
                    id="select-status"
                    options={[
                      ...new Set(mergedAccounts.map((row) => row.statusAds).filter(Boolean)),
                    ]}
                    value={statusFilter}
                    onChange={(event, newValue) => setStatusFilter(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Status" placeholder="All" />
                    )}
                    getOptionLabel={(option) => (option ? option.toString() : "")}
                    sx={{ width: "19%" }}
                  />

                  <Autocomplete
                    options={["", "0-500", "500-999", "1000+"]}
                    value={totalCostRange}
                    onChange={(event, newValue) => setTotalCostRange(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Total Cost Range"
                        placeholder="All"
                      />
                    )}
                    sx={{ width: "19%" }}
                  />
                </Box>
              )}
            </Box>

            <Box sx={{ mb: 2, mt: 2, display: "flex", gap: 2 }}>
              {role === "admin" && (
                <Button variant="outlined" onClick={downloadCSV}>
                  Download CSV
                </Button>
              )}
              <Button variant="outlined" onClick={handleOpenTotalDialog}>
                Open total dialog
              </Button>
            </Box>

            <DataGrid
              rows={filteredData}
              columns={renderedColumns}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 25, page: 0 },
                },
              }}
              pageSizeOptions={[25, 50, 100, { value: -1, label: "All" }]}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
              onColumnWidthChange={handleColumnWidthChange}
              sx={{ ...dataGridStyles, ...dataGridStylesSticky }}
              getRowClassName={(params) =>
                RED_STATUSES.includes(params.row.statusAds)
                  ? "row-red"
                  : params.indexRelativeToCurrentPage % 2 === 0
                  ? "MuiDataGrid-even-row"
                  : ""
              }
            />

            <Dialog open={openDialog} onClose={handleCloseDialog}>
              <DialogTitle>Status History</DialogTitle>
              <DialogContent>
                {dialogError ? (
                  <Typography color="error">{dialogError}</Typography>
                ) : statusHistory ? (
                  <Box>
                    {statusHistory.map((item, index) => (
                      <Typography key={index}>
                        {index + 1}.{" "}
                        {dayjs(item.date).format("DD/MM/YYYY HH:mm")} - {item.status}
                      </Typography>
                    ))}
                  </Box>
                ) : (
                  <Typography>Loading...</Typography>
                )}
              </DialogContent>
              <DialogActions>
                <IconButton size="small" color="primary" onClick={handleCloseDialog}>
                  <CloseIcon />
                </IconButton>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openDailyDialog}
              onClose={handleCloseDailyDialog}
              maxWidth="md"
              fullWidth
            >
              <DialogTitle>
                {`Details for ${selectedSeller} on ${selectedDate}`}
              </DialogTitle>
              <DialogContent>
                <DataGrid
                  rows={dailyDialogData}
                  columns={[
                    {
                      field: "seller",
                      headerName: "Seller",
                      flex: 1,
                      minWidth: 150,
                    },
                    {
                      field: "project",
                      headerName: "Buyer",
                      flex: 1,
                      minWidth: 200,
                    },
                    {
                      field: "totalCost",
                      headerName: "Total Cost",
                      flex: 1,
                      minWidth: 150,
                      sortComparator: (v1, v2) => {
                        const num1 = !isNaN(parseFloat(v1)) ? parseFloat(v1) : 0;
                        const num2 = !isNaN(parseFloat(v2)) ? parseFloat(v2) : 0;
                        return num2 - num1;
                      },
                    },
                  ]}
                  pageSize={5}
                  rowsPerPageOptions={[5, 10]}
                  sx={dataGridStyles}
                  getRowClassName={(params) => {
                    if (params.indexRelativeToCurrentPage % 2 === 0) {
                      return "MuiDataGrid-even-row";
                    }
                    return "";
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDailyDialog} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openTotalDialog}
              onClose={handleCloseTotalDialog}
              maxWidth="xs"
              fullWidth
            >
              <DialogTitle>Total Info</DialogTitle>
              <DialogContent>
                <Typography>Accounts Count: {totalStats.totalAccounts}</Typography>
                <Typography>Total Cost: {totalStats.totalCostAll}</Typography>
                <Typography>Total Leads: {totalStats.totalLeadsAll}</Typography>
                <Typography>Total Deposit: {totalStats.totalDepositAll}</Typography>
                <Typography>Average CPL: {totalStats.avgCpl}</Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseTotalDialog}>Close</Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default AccountsAnalytics;
